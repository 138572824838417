import { Component } from 'react';
import sessionModel from '../model/sessionModel';

/**
 * Base component for views that require a logged in user
 */
export default class UserViewBase extends Component<any, {[key:string]:any}>
{
	protected history:string[];

	constructor(props:any)
	{
		super(props);

		this.history = props.history;
		this.state = {};

		if (!sessionModel.success)
		{
			this.history.push('/');
		}
	}

	// TODO Implement API check, etc, in componentDidMount()
}
