import React, { Component } from 'react';
import sessionModel from '../model/sessionModel';
import loadXeroSso from '../service/loadeXeroSso';
import './LoginView.scss';

export default class HomeView extends Component<any, { [key: string]: any }>
{
	constructor(props: any) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		// TODO Check is user is already logged in

		this.loadConsentUrl();
	}

	async loadConsentUrl() {
		const response = await fetch('/api/consent', {credentials:'same-origin'});
		const data = await response.json();
		const { consentUrl } = data;

		this.setState({ consentUrl });

		loadXeroSso();
	}

	render() {
		return (
			<div className="LoginView">
				<h1>
					<span className="logo-osassess"></span>
					OS Assess
				</h1>
				<p>
					Welcome to OS Assess! This tool, brought to you by OS Accounting chartered accountants, will review
					your accounts to ensure that everything's up-to-date and identify those areas where there's room
					for improvement, a problem to address or that might create difficulties when it comes to preparing
					your end of year accounts or tax returns.
				</p>
				<p>
					By using this tool, you are agreeing to OS Accounting's <a href="https://osaccounts.com/privacy-policy/" target="_blank">terms &amp; conditions and privacy policy</a>.
				</p>
				<div className="xero-sso-link-display">
					<span
						data-xero-sso
						data-href={this.state.consentUrl}
						data-font="inherit"
						data-width="280"
						data-height="72"
						data-label="Sign in with Xero"
						data-size="large"
						data-theme="light"
					></span>
				</div>
			</div>
		);
	}
}
