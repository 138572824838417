/*
const sessionName = 'sessionModel';
const sessionTarget = JSON.parse(sessionStorage.getItem(sessionName) || '{}');

const sessionHandler =
{
	get: (target:any, key:string, receiver:any) =>
	{
		return target[key];
	},

	set: (target:any, key:string, value:any) =>
	{
		target[key] = value;
		sessionStorage.setItem(sessionName, JSON.stringify(target));
		return true;
	},
};

export const sessionModel = new Proxy(sessionTarget, sessionHandler);

export default sessionModel;
*/

// TODO Populate this from the API at runtime

export const sessionModel: { [key: string]: any; } =
{
	user: {},
	tenants: [],

	clear: () => {
		const me = this as any;

		for (const a in me) {
			if (typeof me[a] !== 'function') {
				delete me[a];
			}
		}
	}
};

export default sessionModel;
