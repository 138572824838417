import React from 'react';
import UserViewBase from './UserViewBase';

export default class DashboardView extends UserViewBase
{
	constructor(props:any)
	{
		super(props);
	}

	render()
	{
		return (
			<div>
				<p>
					Dashboard / Welcome
				</p>
			</div>
		);
	}
}
