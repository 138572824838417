import React, { Component } from 'react';
import sessionModel from '../model/sessionModel';
import TrafficLight from 'react-trafficlight';

export default class AdminView extends Component<any, { [key: string]: any; }>
{
	protected history: string[];

	constructor(props: any) {
		super(props);

		this.history = props.history;
		this.state = { results: [] };
	}

	async componentDidMount() {
		try {
			await this.fetchResults();
		}
		catch (e) {
			this.history.push('/');
		}
	}

	async fetchResults() {
		const response = await fetch('/api/results');
		const results = await response.json();

		sessionModel.results = results;

		this.setState({ results });
	}

	// TODO Put this in it's own module
	toTrafficLight(color: string) {
		switch (color) {
			case 'red': return { RedOn: true, YellowOn: false, GreenOn: false };
			case 'amber': return { RedOn: false, YellowOn: true, GreenOn: false };
			case 'green': return { RedOn: false, YellowOn: false, GreenOn: true };
			default: return { RedOn: false, YellowOn: false, GreenOn: false };
		}
	}

	render() {
		return (
			<div className="AdminView">
				<h1>Admin console</h1>
				<div className="breakdown-display">
					{
						this.state.results.map((result, index) => {
							return (
								<div className="result-display" key={index} onClick={() => this.history.push(`/admin/result/${index}`)}>
									<TrafficLight
										className="traffic-light"
										RedOn={this.toTrafficLight(result.color).RedOn}
										YellowOn={this.toTrafficLight(result.color).YellowOn}
										GreenOn={this.toTrafficLight(result.color).GreenOn}
										width="20"
										height="54"
									/>
									<h3>{Math.floor(result.score * 100)}% &ndash; {result.organisation.name}</h3>
									<p>Submitted by {result.user.firstName} {result.user.lastName}, {new Date(result.timestamp).toLocaleString()}</p>
								</div>
							);
						})
					}
				</div>
			</div>
		);
	}
}
