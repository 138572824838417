import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './App.scss';
import AdminView from './view/AdminView';
import AssessView from './view/AssessView';
import DashboardView from './view/DashboardView';
import LoaderView from './view/LoaderView';
import LoginView from './view/LoginView';

export default class App extends Component {
	render() {
		return (
			<>
				<div className="header-contact--mobile">
					<a href="https://osaccounts.com/" target="_blank" className="website-link"><i className="fa fa-external-link-square-alt"></i> Visit our website</a>
					<a href="tel:+441483357651"><i className="fa fa-phone"></i> 01483 357651</a>
					<a href="mailto:info@osaccounts.com"><i className="fa fa-envelope"></i> info@osaccounts.com</a>
				</div>

				<div className="site-header">
					<div className="logo-display"></div>
				</div>

				<div className="site-content">
					<div className="App">
						<header className="App-header">
							<BrowserRouter basename="/">
								<Switch>
									<Route exact path="/" component={LoaderView} />
									<Route path="/callback" component={LoaderView} />
									<Route path="/login" component={LoginView} />
									<Route path="/dashboard" component={DashboardView} />
									<Route path="/assess" component={AssessView} />
									<Route exact path="/admin" component={AdminView} />
									<Route path="/admin/result/:index" component={AssessView} />
									<Redirect from='*' to='/' />
								</Switch>
							</BrowserRouter>
						</header>
					</div>
				</div>

				<footer className="site-footer">
					OS Assess 1.0.0 Copyright © 2021 Mesmotronic Limited &amp; OS Accounting Limited. All rights reserved.<br />
					<a href="https://osaccounts.com/wp-content/uploads/2020/07/TAC6-July2020.pdf" target="_blank">Terms of Business</a>
					&nbsp;|&nbsp;
					<a href="https://osaccounts.com/privacy-policy/" target="_blank">Privacy Policy</a>
					&nbsp;|&nbsp;
					<a href="https://osaccounts.com/contact-us/" target="_blank">Contact</a>
					&nbsp;|&nbsp;
					<a href="https://www.mesmotronic.com/" target="_target">Made by Mesmotronic</a>
				</footer>
			</>
		);
	}
}
