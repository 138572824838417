export default function loadXeroSso()
{
	const existingScript = document.getElementById('xeroSso');

	if (!existingScript)
	{
		const script = document.createElement('script');
		script.id = 'xeroSso';
		script.src = 'https://edge.xero.com/platform/sso/xero-sso.js';
		document.body.appendChild(script);
	}
}
