import React from 'react';
import sessionModel from '../model/sessionModel';
import UserViewBase from './UserViewBase';
import TrafficLight from 'react-trafficlight';
import './AssessView.scss';

export default class AssessView extends UserViewBase {
	constructor(props: any) {
		super(props);

		this.state =
		{
			assessment: { results: [], score: 0 },
			...sessionModel,
		};
	}

	async componentDidMount() {
		let state: any;

		try {
			if (sessionModel.user.isAdmin) {
				const { index } = this.props.match.params;
				const result = sessionModel.results[index];
				state = {
					...result,
					assessment: result,
				};
			} else {
				state = {
					assessment: await this.fetchAssessment()
				};
			}

			this.setState(state);
		}
		catch (e) {
			this.history.push('/login');
		}
	}

	async fetchAssessment(): Promise<any> {
		const response = await fetch('/api/assess', { credentials: 'same-origin' });
		const result = await response.json();

		return result;
	}

	toTrafficLight(color: string) {
		switch (color) {
			case 'red': return { RedOn: true, YellowOn: false, GreenOn: false };
			case 'amber': return { RedOn: false, YellowOn: true, GreenOn: false };
			case 'green': return { RedOn: false, YellowOn: false, GreenOn: true };
			default: return { RedOn: false, YellowOn: false, GreenOn: false };
		}
	}

	render() {
		return (
			<div className="AssessView">
				<h1>{this.state.organisation ? this.state.organisation.name : ''}</h1>

				{ this.state.assessment.results.length === 0 ?
					<div>
						<p>Calculating your score, please wait...</p>
					</div>

					:

					<div>
						{
							sessionModel.user.isAdmin
								? <p>Submitted by {this.state.assessment.user.firstName} {this.state.assessment.user.lastName}, {new Date(this.state.assessment.timestamp).toLocaleString()}</p>
								: <p>Hi {this.state.user.firstName} {this.state.user.lastName}, here are your OS Assess results!</p>
						}
						<div className="summary-display">
							<TrafficLight
								className="traffic-light"
								RedOn={this.toTrafficLight(this.state.assessment.color).RedOn}
								YellowOn={this.toTrafficLight(this.state.assessment.color).YellowOn}
								GreenOn={this.toTrafficLight(this.state.assessment.color).GreenOn}
							/>
							<h3>You scored</h3>
							<h2>{Math.round(this.state.assessment.score * 100)}%</h2>
						</div>
						<div className="breakdown-display">
							{
								this.state.assessment.results.map((result: any) => {
									return (
										<div className="result-display" key={result.key}>
											<TrafficLight
												className="traffic-light"
												RedOn={this.toTrafficLight(result.color).RedOn}
												YellowOn={this.toTrafficLight(result.color).YellowOn}
												GreenOn={this.toTrafficLight(result.color).GreenOn}
												width="20"
												height="54"
											/>
											<h3>{result.name}</h3>
											<p>{result.description}</p>
										</div>
									);
								})
							}
						</div>
					</div>
				}
			</div>
		);
	}
}
