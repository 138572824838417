import React, { Component } from 'react';
import sessionModel from '../model/sessionModel';
import './LoaderView.scss';

export default class LoaderView extends Component {
	protected history: string[];
	protected params: any;

	constructor(props: any) {
		super(props);

		this.history = props.history;
		this.state = {};
		this.params = Object.fromEntries(new URLSearchParams(window.location.search));
	}

	componentDidMount() {
		this.updatePath();
	}

	async updatePath() {
		if (this.params.code) {
			// TODO Talk to the API to verify login, etc

			try {
				const response = await fetch('/api/callback?' + new URLSearchParams(this.params), { credentials: 'same-origin' });
				const data = await response.json();

				if (data.success) {
					Object.assign(sessionModel, data);
					this.history.push(data.user.isAdmin ? '/admin' : '/assess');
					return;
				}
			}
			catch (e) {
				// Ignore for now...
			}
		}

		// TODO Is the user already logged in?

		this.history.push('/login');
	}

	render() {
		return (
			<div className="LoaderView">
				<p>
					Loading, please wait...
				</p>
			</div>
		);
	}
}
